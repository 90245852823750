// -----------------------------------------------------------------------------------------------------
// @ variables
// -----------------------------------------------------------------------------------------------------
// theme headers 
$carded-header-height: 135px;
$carded-toolbar-height: 65px;
$header-height: 85px;
$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@import 'ngx-toastr/toastr';
@import './../node_modules/ngx-lightbox/lightbox.css';

@import "codemirror/lib/codemirror";
@import "codemirror/theme/material";

@import './../node_modules/@fortawesome/fontawesome-free/css/all.css';
// @import '~@fortawesome/fontawesome-free/scss/fontawesome';
// @import '~@fortawesome/fontawesome-free/scss/brands';
// @import '~@fortawesome/fontawesome-free/scss/solid';
// @import '~@fortawesome/fontawesome-free/scss/regular';
// Import Common/Global Styles
@import "app/styles/app.common";