@mixin quick-panel-theme($theme) {
    $accent: map-get($theme, accent);

    quick-panel {
         .timeline-sidebar {
                height: 75vh;
                overflow-y: scroll;
                .latest-activity {

                    .content {
                        
                        .activities {

                            .activity {

                                .username {
                                    // color: map-get($accent, default);
                                }
                                                              
                            }
                        }
                    }
                }
            }
        }
    
}